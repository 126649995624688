<script lang="ts">
import { useNhostClient } from "@nhost/vue"
export default {
  data() {
    return {}
  },
  created() {
    const client  = useNhostClient()
    client.nhost.auth.refreshSession().then(response => {
      window.location.replace(`${import.meta.env.VITE_DATAROOM_REDIRECT_URL}${response.session?.refreshToken}`);
    });
  },
}
</script>

<template>
  <main></main>
</template>
