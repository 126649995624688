<script lang="ts">
import { useSignOut } from '@nhost/vue'

export default {
  data() {
    return {}
  },
  created() {
    const { signOut } = useSignOut()
    signOut()
    this.$router.push('/sign-in')
  }
}
</script>

<template>
  <main></main>
</template>
