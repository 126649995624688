<script lang="ts">
import { useSignInEmailPassword, useChangePassword } from '@nhost/vue'
import { useLocale } from "vuetify";

export default {
  setup() {
    const { t } = useLocale();
    const getTranslation = (path: string) => t(path);
    return {
      getTranslation
    }
  },
  data() {
    return {
      password: '',
      passwordConfirm: '',
      needsEmailVerification: false,
      visible: false,
      rememberMe: false,
      passwordMismatchAlert: false,
      changePassword: undefined,
    } as any
  },
  created() {
    const { user } = useSignInEmailPassword()
    if (!user.value?.id) this.$router.push('/forgot-password')
    const { changePassword } = useChangePassword();
    this.changePassword = changePassword as any;
  },
  methods: {
    async handleSubmit(event: Event) {
      event.preventDefault();
      this.passwordMismatchAlert = false;

      if (this.password !== this.passwordConfirm) {
        this.passwordMismatchAlert = true;
      } else {
        const { isSuccess } = await this.changePassword(this.password);
  
        if (isSuccess) {
          this.$router.push('/app')
        }
      }
    },
    async handleForgotPassword(event: Event) {
      this.$router.push('/forgot-password')
    }
  }
}
</script>

<template>
  <div class="change-password pa-10 d-flex">
    <div class="w-75">
      <div class="d-flex justify-center">
        <v-img eager style="max-width: 80%;" class="mb-10" src="@/assets/logo_cockpit_full_svg.svg" />
      </div>
      <div class="justify-start">
        <h3>{{ getTranslation('$vuetify.auth.change-password.message.title') }}</h3>
        <p class="change-password-text-body">{{ getTranslation('$vuetify.auth.change-password.message.text') }}</p>
      </div>
      <br />

      <v-form @submit="handleSubmit">
        <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
          variant="outlined" v-model="password" @click:append-inner="visible = !visible"
          :label="getTranslation('$vuetify.auth.change-password.password.label')" />
        <br />
        <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
          variant="outlined" v-model="passwordConfirm" @click:append-inner="visible = !visible"
          :label="getTranslation('$vuetify.auth.change-password.confirmPassword.label')" />
        <br />
        <v-alert v-model="passwordMismatchAlert" type="error" variant="tonal" color="danger" border="start" closable :text="getTranslation('$vuetify.auth.change-password.password-mismatch-alert.text')" />
        <br />
        <v-btn min-width="100%" color="primary" class="text-none" type="submit">
          <span class="text-white">
            {{ getTranslation('$vuetify.auth.change-password.confirm-btn.text') }}
          </span></v-btn>
        <br />
      </v-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.change-password {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.forgot-password-text {
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
}

.change-password-text-body {
  color: rgb(var(--v-theme-text-body-secondary));
}
</style>
