/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify'
import nhost, { apolloProvider } from './nhost'
import i18n from './i18n'
import pinia from '../store'
import router from '../router'
import Hotjar from 'vue-hotjar'
import { initSentry } from './sentry'

// Types
import type { App } from 'vue'

export function registerPlugins (app: App) {
  initSentry(app)
  app
    .use(i18n)
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(nhost)
    .use(apolloProvider)
    .use(Hotjar, {
      id: '3716114' // Hotjar Site ID
    })
}
