<script lang="ts">
import { useSignInEmailPassword } from '@nhost/vue'
import { useLocale } from "vuetify";
import { useSigninStore } from '@/store/signin'
import { useSignOut, useNhostClient } from '@nhost/vue'

export default {
  setup() {
    const { t } = useLocale();
    const getTranslation = (path: string) => t(path);
    const { signOut } = useSignOut()
    const client  = useNhostClient()
    const getSession = () => client.nhost.auth.refreshSession()
    return {
      getTranslation,
      signOut,
      getSession
    }
  },
  data() {
    return {
      email: '',
      password: '',
      needsEmailVerification: false,
      signInEmailPassword: undefined,
      visible: false,
      rememberMe: false,
      invalidCredentialsAlert: false,
    } as any
  },
  created() {
    const { signInEmailPassword, user } = useSignInEmailPassword()

    if (user.value?.id) this.$router.push('/app')
    this.signInEmailPassword = signInEmailPassword as any

    const store = useSigninStore()

    if (store.getRememberMe) {
      this.email = store.getRememberMe.email
    }
  },
  methods: {
    async handleSubmit(event: Event) {
      event.preventDefault();

      this.invalidCredentialsAlert = false;

      const { isSuccess, needsEmailVerification, user } = await this.signInEmailPassword(
        this.email,
        this.password
      )
      this.needsEmailVerification = needsEmailVerification

      if (isSuccess) {
        if (this.rememberMe) {
          const store = useSigninStore()
          store.setRememberMe({ email: this.email })
        }
        if (user.defaultRole === 'investor') {
          const { session } = await this.getSession();
          window.location.replace(`${import.meta.env.VITE_APP_CONTROLTOWER_URL}/sign-in-redirect?token=${session?.refreshToken}`);
        }
        else if (user.defaultRole === 'user') this.$router.push('/app')
      }
      else {
        this.invalidCredentialsAlert = true;
      }
    },
    async handleForgotPassword(event: Event) {
      this.$router.push('/forgot-password')
    },
    async handleFirstAccess(event: Event) {
      this.$router.push('/first-access')
    }
  }
}
</script>

<template>
  <div class="sign-in pa-10 d-flex">
    <div class="w-75">
      <div class="d-flex justify-center">
        <v-img eager style="max-width: 80%;" class="mb-10" src="@/assets/logo_cockpit_full_svg.svg" />
      </div>

      <p v-if="needsEmailVerification">
        {{ getTranslation('$vuetify.auth.sign-in.needsEmailVerification.text') }}
      </p>

      <v-form @submit="handleSubmit" v-else>
        <v-text-field variant="outlined" v-model="email" type="email"
          :label="getTranslation('$vuetify.auth.sign-in.email.label')" />
        <br />
        <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
          variant="outlined" v-model="password" @click:append-inner="visible = !visible"
          :label="getTranslation('$vuetify.auth.sign-in.password.label')" />
        <br />
        <v-btn min-width="100%" color="primary" class="text-white" type="submit">
          <span class="text-white">{{ getTranslation('$vuetify.auth.sign-in.login-btn.text') }}</span></v-btn>
        <br />
        <br />
        <v-alert v-model="invalidCredentialsAlert" type="error" variant="tonal" color="danger" border="start" closable :text="getTranslation('$vuetify.auth.sign-in.wrong-credentials.text')" />
        <div class="d-flex flex-row justify-space-between mt-5">
          <v-checkbox v-model="rememberMe"
            :label="getTranslation('$vuetify.auth.sign-in.remember-me-checkbox.text')"></v-checkbox>
          <p class="ma-4 forgot-password-text" @click="handleForgotPassword">{{
            getTranslation('$vuetify.auth.sign-in.forgot-password-btn.text') }}</p>
          <p class="ma-4 first-access-text" @click="handleFirstAccess">{{
            getTranslation('$vuetify.auth.sign-in.first-access-btn.text') }}</p>
        </div>
      </v-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sign-in {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.first-access-text {
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
}

.forgot-password-text {
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
}
</style>
