import * as Sentry from "@sentry/vue";
import { App } from "vue";

export const initSentry = (app: App) => {
    Sentry.init({
        app,
        dsn: "https://64dd07d1a3b1555e3fa7b7d58accfcb1@o4507447678795776.ingest.us.sentry.io/4507447680958464",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/apigql.generalfinance\.com\.br/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
}
