import { defineStore } from 'pinia'

export const STORE_NAME = 'signin'

export const useSigninStore = defineStore(STORE_NAME, {
    state: () => ({ rememberMe: { email: "" } }),
    getters: {
      getRememberMe: (): { email: string } => {
        const signinLocalStore = localStorage.getItem(STORE_NAME)
        try {
          return signinLocalStore ? JSON.parse(signinLocalStore).rememberMe : {}
        } catch {
          return { email: "" };
        }
      }
    },
    actions: {
      setRememberMe(rememberMe: { email: string }) {
        this.rememberMe = rememberMe;
        localStorage.setItem(STORE_NAME, JSON.stringify(this.$state))
      }
    },
  })