/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Composables
import { createVuetify } from "vuetify";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";

import i18n from "./i18n";
import { useI18n } from "vue-i18n";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {},
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#39BFE2",
          secondary: "#01090E",
          "text-body-secondary": "#768189",
          "gray-600": "#0F1E24",
          "primary-800": "#041B2E",
          "primary-200": "#A0C4E2",
          "gray-50": "#F4F5F5",
          "gray-100": "#F6F6F9",
          "gray-200": "#9AA0A4",
          "gray-300": "#768189",
          success: "#00A85A",
          "warning-dark": "#655B01",
          danger: "#CE1515",
          "danger-light": "#FBCECE",
        },
      },
      dark: {
        colors: {
          primary: "#39BFE2",
          secondary: "#5CBBF6",
          "text-body-secondary": "#768189",
          "gray-600": "#0F1E24",
          "primary-800": "#041B2E",
          "primary-200": "#A0C4E2",
          "gray-50": "#F4F5F5",
          "gray-100": "#F6F6F9",
          "gray-200": "#9AA0A4",
          "gray-300": "#768189",
          success: "#00A85A",
          "warning-dark": "#655B01",
          danger: "#CE1515",
          "danger-light": "#FBCECE",
        },
      },
    },
  },
});
