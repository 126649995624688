<script lang="ts">
import { useResetPassword, useSignInEmailPassword } from '@nhost/vue'
import { useLocale } from "vuetify";
export default {
  setup() {
    const { t } = useLocale();
    const getTranslation = (path: string) => t(path);
    return {
      getTranslation
    }
  },
  data() {
    return {
      email: '',
      resetPassword: (email: string) => console.log(email),
      sentEmailAlert: false,
    }
  },
  created() {
    const { resetPassword } = useResetPassword({
      redirectTo: import.meta.env.VITE_APP_CHANGE_PASSWORD_URL
    })
    const { user } = useSignInEmailPassword()
    if (user.value?.id) this.$router.push('/app')
    this.resetPassword = resetPassword as any;
  },
  methods: {
    async handleSubmit(event: Event) {
      event.preventDefault();
      await this.resetPassword(this.email);
      this.sentEmailAlert = true;

    },
    async handleRedirectTo(route: string) {
      this.$router.push(route)
    }
  }
}
</script>

<template>
  <div class="first-access pa-10 d-flex">
    <div class="w-75">
      <v-img eager height="75" class="mb-5" src="@/assets/logo_cockpit_full_svg.svg" />
      <div class="justify-start">
        <h3>{{ getTranslation('$vuetify.auth.first-access.message.title') }}</h3>
        <p class="first-access-text-body">{{ getTranslation('$vuetify.auth.first-access.message.text') }}</p>
      </div>
      <v-form @submit="handleSubmit">
        <v-text-field class="mt-3" variant="outlined" v-model="email" type="email" :label="getTranslation('$vuetify.auth.first-access.email.label')" />
        <br />
        <v-alert v-model="sentEmailAlert" type="success" variant="tonal" color="success" border="start" closable :text="getTranslation('$vuetify.auth.first-access.email-sent-alert.text')" />
        <br />
        <v-btn class="btn-submit text-none" color="primary" min-width="100%" type="submit">
          <span class="text-white">
            {{ getTranslation('$vuetify.auth.first-access.send-link-btn.text') }}
          </span>
        </v-btn>
        <div class="d-flex flex-row-reverse">
          <p class="mt-5 sign-in-text" @click="handleRedirectTo('sign-in')">{{ getTranslation('$vuetify.auth.first-access.sign-in-btn.text') }}</p>
        </div>
      </v-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.first-access {
  justify-content: center;
  align-items: center;
}

.sign-in-text {
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
}

.first-access-text-body {
  color: rgb(var(--v-theme-text-body-secondary));
}
</style>
